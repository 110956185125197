import Img from "gatsby-image"
import styled from "@emotion/styled";
import { Paper, Theme, Typography, Box } from "@material-ui/core";
import { Link } from "gatsby";
import { ComponentType } from "react";
import ReactMarkdown from "react-markdown";

export const RoundedImg = styled(Img)`
  border-radius:16px;
`;

export const BottomRoundedImg = styled(Img)`
  border-radius:0px 0px 16px 16px;
`;

export const RoundPaper = styled(Paper)`
  border-radius: 16px;
`;

export const TextOverlayType = styled(Typography, {
  shouldForwardProp: prop => prop !== "shadowColor"
})((props: {shadowColor: string}) =>`
    text-shadow: 1px 1px 25px ${props.shadowColor}4D;
`);

type boxProps = import('@material-ui/core/Box').BoxProps;
export const BlogContentBox = styled<ComponentType<boxProps>, {theme: Theme}>(Box)(props =>`
  padding: 0px 48px;
  ${props.theme.breakpoints.down("md")} {
    padding: 0px 0px;
  }
`);

export const UnderlineOnHoverLink = styled(Link)`
  text-decoration: none;
  color: #000;
  &:hover {
      text-decoration: underline;
  }
`;

export const StyledMarkdown = styled(ReactMarkdown)`
  font-size: 1rem;
`;